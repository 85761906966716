import React from "react"

import Script from "next/script"

import { ThemeProvider } from 'next-themes'

import { gql } from "@apollo/client"
import craftApolloClient from "./api/apollo"

import { Header } from "../fuselage/global/header/header"
import { Footer } from '../fuselage/theme/2024/layout/footer/footer'

import '../css/index.css'


const defaultState = {}
export const GlobalContext = React.createContext(defaultState)



function App({ Component, pageProps, globals }) {

	// console.log('globals:', globals)

	return (
		<>
			<GlobalContext.Provider value={globals}>
				<ThemeProvider forcedTheme={Component.theme || null}>
					{/* <Script id="google-tag-manager" strategy="afterInteractive">
						{`
							(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
							new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
							j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
							'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
							})(window,document,'script','dataLayer','GTM-MNM37BX');
						`}
					</Script> */}
					<Component {...pageProps} />
				</ThemeProvider>
			</GlobalContext.Provider>
		</>
	)
}

App.getInitialProps = async () => {

	const { data } = await craftApolloClient().query({
		query: gql`
			query Globals {
				nodes(site: "${process.env.SITE_HANDLE}", navHandle: "${process.env.SITE_HANDLE}MainMenu", level: 1) {
					id
					title
					url
					level
					classes
					newWindow
					children {
						id
						title
						url
						level
						classes
						newWindow
					}
				}
				globalSets(site: "${process.env.SITE_HANDLE}") {
					... on footer_GlobalSet {
						id
						name
						socialConnections {
							... on socialConnections_BlockType {
								id
								hyperlink
								icon
								label
							}
						}
						contactDetails {
							... on contactDetails_BlockType {
								id
								body
							}
						}
						hubspotForm {
							... on hubspotForm_BlockType {
								id
								formId
								portalId
							}
						}
					}
				}
			}
		
		`
	})

	return { 
		globals: data
	}

}

export default App